<script setup>
import PageTitleCard from '@/components/PageTitleCard.vue';
import { TRANSPORT_ORDERS_ROUTE } from '@/constants/constants';
import { setPageTitle } from '@/utils/pageTitle';
import { onMounted, ref } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const statuses = ref([
    { id: 0, name: 'All' },
    { id: 2, name: 'Pending' },
    { id: 10, name: 'In-Transit' },
    { id: 11, name: 'Delivered' },
]);
const loads = ref( [
        {
            id: 37,
            uuid: "311334d9-5e65-4e90-a054-7cfc1a056c70",
            number: "LD-00037",
            created_at: "8/15/24",
            status_id: 2,
            status_name: "Pending Pickup",
            status_color: "#DC7633"
        }
    ]);
const selectedLoad = ref(null);
const orders = ref([]);

onMounted(() => {
    setPageTitle('Transportation Loads');
})

const selectLoad = (load) => {
    selectedLoad.value = load;
    orders.value = [
    {
        id: 7011,
        uuid: "22beb5ed-4cff-480c-ad1c-c7feed539849",
        number: "TR-07011",
        client_id: 221,
        client_name: "Breakthru",
        pickup: "NORTH BERGEN, NJ",
        dropoff: "North Wales, PA",
        po_number: "4501073260",
        customer_ref: "",
        cargo: "66.0 ",
        status_id: 11,
        status_name: "Delivered",
        status_color: "green",
        pick_appt: null,
        pick_appt_confirmed: 0,
        drop_appt: null,
        drop_appt_confirmed: 0
    },
]
}

</script>

<template>
    <PageTitleCard>
        <template #actions>
            <v-btn
                class="font-weight-bold mr-2"
                color="primary"
                @click="router.push(`/${TRANSPORT_ORDERS_ROUTE}`)" >Manage Orders
            </v-btn>
            <v-btn
                class="font-weight-bold"
                color="primary">
                New Load
            </v-btn>
        </template>
    </PageTitleCard>
    <div class="d-flex border-t mt-4">
        <v-list width="250" class="border-e" height="calc(100vh - 50px)" dense>
            <v-list-subheader>STATUS</v-list-subheader>
                <v-list-item class="d-flex" v-for="item in statuses" :key="item.id" :value="item.id">
                  <template v-slot:prepend>
                    <v-icon>{{ false ? 'mdi-folder-open' : 'mdi-folder' }}</v-icon>
                  </template>
                  <v-list-item-title>
                    {{ item.name }}
                  </v-list-item-title>
                </v-list-item>
          </v-list>
    
          <v-list width="450" class="border-e" height="calc(100vh - 50px)">
            <v-list-item v-if="loads && loads.length == 0">
              <v-list-item-content class="text-disabled text-center">
                No Loads for this status
              </v-list-item-content>
            </v-list-item>
            <v-list-item
              v-for="load in loads"
              :key="load.id"
              class="border-bottom pointer"
              link
              @click="selectLoad(load)"
            >
              <template v-slot:prepend>
                  <v-icon>mdi-truck</v-icon>
              </template>
                <v-list-item-title>{{ load.number }} -
                  {{ load.created_at }}
                </v-list-item-title>
                <v-list-item-subtitle>
                  Driver: <small>N/A</small> &bull;
                  Trailer: <small>N/A</small>
                </v-list-item-subtitle>
            </v-list-item>
        </v-list>

        <div class="w-100" v-if="selectedLoad">
          <div style="padding-bottom: 0;height: 450px; overflow: hidden; border-bottom: 2px solid #cfcfcf;">
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3060.521817825358!2d-75.26168968414738!3d39.907337179427614!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c38d775f337b%3A0xde155279b1832a40!2s2009%20Elmwood%20Ave%2C%20Sharon%20Hill%2C%20PA%2019079!5e0!3m2!1sen!2sus!4v1609416926292!5m2!1sen!2sus"
              frameborder="0"
              style="margin-bottom: 0; padding-bottom: 0; width: 100%;height: 450px;"
              allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
          </div>
          <div class="load-bar border-b">
            <div>
              {{ selectedLoad.number }} &bull;
              {{ selectedLoad.created_at }} &bull;
              {{ selectedLoad.status_name}}
            </div>
            <v-btn elevation="0" size="small"
              v-if="selectedLoad.status_id == 2 || selectedLoad.status_id == 1">Mark In-Transit</v-btn>
            <v-btn elevation="0" size="small"
              v-if="selectedLoad.status_id == 10">Mark Delivered</v-btn>
          </div>
          <!-- <%= erb :'/transports/loads/partials/index_status' %> -->
          <table class="w-100 text-center" v-if="orders && orders.length > 0">
            <thead>
              <tr>
                <th>Order #</th>
                <th>Pickup</th>
                <th>PO #</th>
                <th>Cargo</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
                <tr v-for="(order, index) in orders" :key="index">
                  <td>
                    <a :href="`/${TRANSPORT_ORDERS_ROUTE}/loads/${order.uuid}` + order.uuid">{{ order.number }}</a>
                  </td>
                  <td>{{ order.pickup }} &rarr;{{ order.dropoff }}</td>
                  <td>{{ order.po_number }}</td>
                  <td><small>{{ order.cargo }}</small></td>
                  <td>
                    <v-btn size="x-small" variant="outlined">
                      {{ order.status_name }}
                    </v-btn>
                  </td>
                </tr>
            </tbody>
          </table>
          <div v-else class="d-flex justify-center align-center text-disabled w-100 mt-4">
            No Orders in this Load
          </div>
        </div>
    </div>
    
</template>


<style scoped>
.load-bar {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;
}
</style>